<template>
    <div>
        <div id="header">
            <div class="wrap">
                <div class="left-a">
                    <div class="logo" @click="logo"></div>
                    <div class="text">人生太短 我要争分夺秒</div>
                </div>
                <div class="right-a">
                    <div class="link-home"><a @click="goHome">首页</a></div>
                    <div class="work" @click="work">
<!--                        <i class="iconfont iconshouye"></i>-->
                        工作区
                    </div>
                    <div
                        style="margin-left: 30px"
                        @click="goDown"
                    >
                        <el-dropdown placement="top" class="app-link">
                            <span class="el-dropdown-link">
                                <i class="iconfont iconmobilephone" style="color: #333333"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!--                显示二维码-->
                                <div
                                    style="width: 200px"
                                    ref="inform"
                                    class="link-inform"
                                >
                                    <div class="triangle-up"></div>
                                    <img
                                        style="
                                            display: block;
                                            width: 160px;
                                            height: 160px;
                                            margin: 0 auto 0 auto;
                                        "
                                        src="../assets/images/app.png"
                                    />
                                    <div
                                        class="bot-txt"
                                        style="
                                            text-align: center;
                                            color: #333333;
                                            font-size: 14px;
                                        "
                                    >
                                        扫码下载手机客户端
                                    </div>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <span style="padding-left: 6px;cursor: pointer;" @click="goDown"></span>
                    <!-- 通知按钮 -->
                    <div v-if="username" class="notice">
                        <i class="iconfont iconnews" style="color: #333333" @click="getNotice"></i>
                        <span
                            v-if="NoticeNum > 0"
                            class="noticeActive"
                            @click="getNotice"
                            >{{ NoticeNum }}</span
                        >
                        <div class="inform" v-show="isShowNotice">
                            <div class="triangle-up"></div>
                            <div class="info-head" v-show="pId === null">
                                <a
                                    href="javascript:;"
                                    v-for="(item, index) in noticeData"
                                    :key="index"
                                    @click="active(index)"
                                    :class="{ aActive: activeIndex === index }"
                                    >{{ item }}</a
                                >
                            </div>
                            <!-- 通知列表 -->
                            <div class="noticeCent" v-show="activeIndex === 0">
                                <ul class="noticeList">
                                    <li
                                        v-for="item in noticeList.list"
                                        :key="item.id"
                                    >
                                        <div class="title">
                                            {{ item.title }}：
                                        </div>
                                        <div
                                            class="itemTxt"
                                            v-html="item.content"
                                        ></div>
                                    </li>
                                </ul>
                                <div class="pagin" v-if="pagSum > 1">
                                    <el-pagination
                                        small
                                        layout="prev, pager, next"
                                        :total="pagSum * 10"
                                        @current-change="currentchange"
                                        @prev-click="prevClick"
                                        @next-click="nextclick"
                                    ></el-pagination>
                                </div>
                            </div>
                            <!-- 私信列表 -->
                            <div
                                class="privateCent"
                                v-show="activeIndex === 1 && pId === null"
                            >
                                <ul
                                    class="privateItem"
                                    v-if="infoList.length > 0"
                                >
                                    <li
                                        v-for="item in infoList"
                                        :key="item.id"
                                        @click="enterChat(item.id)"
                                    >
                                        <span class="userId">客服</span>
                                        <div>
                                            <span
                                                v-if="item.type == 2"
                                                class="itemTxt"
                                                >[图片]</span
                                            >
                                            <span v-else class="itemTxt">{{
                                                item.content
                                            }}</span>
                                            <span class="time">{{
                                                item.created_date
                                            }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="privateItem" v-else>
                                    <li @click="enterChat(1)">
                                        <span class="userId">{{ intoT }}</span>
                                    </li>
                                </ul>
                                <div class="pagin" v-if="privateSum > 1">
                                    <el-pagination
                                        small
                                        layout="prev, pager, next"
                                        :total="privateSum * 10"
                                        @current-change="privatechange"
                                        @prev-click="privateprev"
                                        @next-click="privatenext"
                                    ></el-pagination>
                                </div>
                            </div>
                            <!-- 聊天窗口 -->
                            <div class="chat" v-show="activeIndex === 1 && pId">
                                <!-- 头部 -->
                                <div class="chatTitle">
                                    <div class="back">
                                        <div @click="back"></div>
                                    </div>
                                    <div class="centent">客服</div>
                                    <div class="close">
                                        <div @click="close">X</div>
                                    </div>
                                </div>
                                <!-- 聊天区域 -->
                                <div class="chatBoard">
                                    <el-main class="main" id="chat-main">
                                        <div class="chat-body">
                                            <label
                                                v-for="(
                                                    item, index
                                                ) in chat_list"
                                                :key="index"
                                            >
                                                <div
                                                    class="chat-time"
                                                    v-if="item.show_time"
                                                >
                                                    <span>{{
                                                        item.created_date
                                                    }}</span>
                                                </div>
                                                <!-- 左客服 -->
                                                <div
                                                    class="chat-box chat-l"
                                                    v-if="item.pos == 1"
                                                >
                                                    <div class="chat-content">
                                                        <!-- <div class="chat-cont-t"><span>{{item.username}}</span></div> -->
                                                        <div
                                                            class="chat-cont-b"
                                                        >
                                                            <div
                                                                class="triangle-topleft"
                                                            ></div>
                                                            <div
                                                                class="chat-txt"
                                                            >
                                                                <p
                                                                    v-if="item.type == 1" v-html="item.content"
                                                                ></p>
                                                                <p
                                                                    v-if="item.type == 2 && Array.isArray(item.content)"
                                                                >
                                                                    <img v-for="(val, ind) in item.content" :key="ind" v-lazy="val">
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 右用户 -->
                                                <div
                                                    class="chat-box chat-r"
                                                    v-if="item.pos == 2"
                                                >
                                                    <div class="chat-content">
                                                        <!-- <div class="chat-cont-t"><span>{{item.username}}</span></div> -->
                                                        <div
                                                            class="chat-cont-b"
                                                        >
                                                            <div
                                                                class="chat-txt"
                                                            >
                                                                <p
                                                                    v-if="item.type == 1"
                                                                    v-html="item.content"
                                                                ></p>
                                                                <p
                                                                    v-if="item.type == 2 && Array.isArray(item.content)"
                                                                >
                                                                    <img v-for="(val, ind) in item.content" :key="ind" v-lazy="val">
                                                                </p>
                                                            </div>
                                                            <div
                                                                class="triangle-topright"
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </el-main>
                                </div>
                                <!-- 输入区域 -->
                                <div class="sendinfo">
                                    <div class="txtbox">
                                        <textarea
                                            maxlength="1000"
                                            placeholder="发消息给客服"
                                            v-model="send_text"
                                        ></textarea>
                                        <div
                                            class="maxlengthTips"
                                            v-show="!send_text"
                                        >
                                            1000
                                        </div>
                                    </div>
                                    <div class="sendBox">
                                        <div
                                            class="img"
                                            @click="selImg"
                                            title="选择图片"
                                        >
                                            <i class="iconfont icontupian"></i>
                                        </div>
                                        <input
                                            type="file"
                                            @change="imgChange"
                                            name="img-file"
                                            id="img-file"
                                        />
                                        <span
                                            v-show="imgs.length > 0"
                                            class="imgSum"
                                            @click="delImg"
                                            title="删除图片"
                                            >{{ imgs.length }}</span
                                        >
                                        <div class="sendBtn">
                                            <a href="javascript:;" @click="send"
                                                >发送</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="username" class="username">
                        用户名：{{ username }}
                    </div>
                    <div v-else class="login">
                        <a class="register" @click="goRegister">注册</a>
                        <a class="Sign-in" @click="goLogin">登录</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            path: "",
            username: "",
            isShowNotice: false,
            noticeData: ["通知", "私信"],
            activeIndex: 0,
            pId: null,
            NoticeNum: null, // 未读的通知数
            requestPage: 1, // 请求的页数
            noticeList: {}, //通知列表
            countPage: null, //总条数
            curPage: null, // 当前页数
            pagSum: null, //总页数
            privatePage: 1, //私信页数
            privateCount: null, //私信列表总条数
            privateCur: null, //私信列表当前页数
            privateSum: null, //私信总页数
            infoList: [], //私信列表
            chat_list: [], //聊天记录
            toId: -1, //接收者的id
            send_text: "", //发送的文本
            send_img: "", //发送的图片
            imgs: [], //图片集合
            intoT: '',//
            AppLinkMouse: false
        };
    },
    created () {
        this.username = localStorage.j_username;
        this.path = this.$route.path;
        if (localStorage.j_token) {
            this.getNoticeNum();
        }
    },
    methods: {
        goDown () {
            console.log("进入下载页面")
            this.$router.push("downloadApp")
        },
        // 删除选择的图片
        delImg () {
            this.imgs = [];
        },
        //选择图片
        selImg () {
            document.getElementById("img-file").click();
        },
        //发送消息
        send () {
            var that = this;
            var data = {};
            // 图文
            if (that.imgs.length > 0 && that.send_text) {
                let imgData = {};
                that.uploadImgs(that.imgs, function (res) {
                    if (!res) return;
                    imgData.type = 2;
                    imgData["content"] = res;
                    that.saveMessage(imgData);
                });
                let txtData = {};
                if (that.send_text.length > 1000) {
                    that.$message.error("内容长度不能超过1000");
                    return;
                }
                txtData["content"] = that.send_text;
                //追加到聊天右边
                txtData.type = 1;
                that.saveMessage(txtData);
            }
            // 图
            if (that.imgs.length > 0 && !that.send_text) {
                that.uploadImgs(that.imgs, function (res) {
                    if (!res) return;
                    data.type = 2;
                    data["content"] = res;
                    that.saveMessage(data);
                });
            }
            // 纯文本
            if (that.send_text && that.imgs.length == 0) {
                if (that.send_text.length > 1000) {
                    that.$message.error("内容长度不能超过1000");
                    return;
                }
                data["content"] = that.send_text;
                //追加到聊天右边
                data.type = 1;
                that.saveMessage(data);
            }
            // that.getChatRecord();
            that.scrollToBottom();
            that.send_text = "";
            that.imgs = [];
        },
        //获取历史聊天记录
        getChatRecord () {
            this.$http({
                url: this.api.messageList,
            }).then((res) => {
                console.log("聊天记录", res);
                if (!res.code) {
                    //that.chat_list=res.data
                    for (var i in res.data) {
                        this.appendChat(res.data[i]);
                    }
                    this.scrollToBottom();
                    this.changeUnRead();
                }
            });
        },
        //将未读消息改为已读
        changeUnRead () {
            this.$http({
                url: this.api.messageChange,
            }).then((res) => {
                console.log("标记未读消息", res);
            });
        },
        //保存聊天记录
        saveMessage (data) {
            console.log("data", data);
            this.$http({
                url: this.api.messageSave,
                data,
            }).then((res) => {
                console.log("保存聊天记录", res);
                if (res.code != 0) {
                    this.$message.error(res.msg);
                }
                data["pos"] = 2;
                this.appendChat(data);
                this.scrollToBottom();
            });
        },
        //添加聊天记录到列表
        appendChat (data) {
            //data['data'] = data['data'].replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, "'")
            if (data.show_time == null || data.show_time == undefined) {
                data.show_time = 0;
            }
            if (data.type == 2) {
                data["content"] = data["content"].split(",");
                console.log(data["content"]);
            }
            this.chat_list.push(data);
            console.log(data);
        },
        //中间内容滑动到底部
        scrollToBottom () {
            let that = this;
            setTimeout(function () {
                that.$nextTick(() => {
                    console.log("scrollToBottom");
                    let main = document.querySelector(".chatBoard"); // 获取对象
                    console.log("main", main);
                    main.scrollTop = main.scrollHeight; // 滚动高度
                    console.log("main.scrollHeight", main.scrollHeight);
                });
            }, 300);
        },
        //选择图片成功，处理源文件
        imgChange (event) {
            console.log(event);
            if (this.imgs.length > 0)
                return this.$message.warning({
                    message: "每次最多只能选择一张图片",
                    center: true,
                });
            console.log(" imgChange event", event);
            var that = this;
            var reader = new FileReader();
            reader.onload = function () {
                console.log("base64的数据", this.result);
                that.imgs.push(this.result);
            };
            reader.readAsDataURL(event.target.files[0]);
            event.target.value = "";
        },
        //压缩图片
        dealImage: function (base64, w, callback) {
            var newImage = new Image();
            var quality = 0.6; //压缩系数0-1之间
            newImage.src = base64;
            newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
            var imgWidth, imgHeight;
            newImage.onload = function () {
                imgWidth = this.width;
                imgHeight = this.height;
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                if (Math.max(imgWidth, imgHeight) > w) {
                    if (imgWidth > imgHeight) {
                        canvas.width = w;
                        canvas.height = (w * imgHeight) / imgWidth;
                    } else {
                        canvas.height = w;
                        canvas.width = (w * imgWidth) / imgHeight;
                    }
                } else {
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                    quality = 0.6;
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
                // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                // while (base64.length / 1024 > 150) {
                // 	quality -= 0.01;
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
                // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                // while (base64.length / 1024 < 50) {
                // 	quality += 0.001;
                // 	base64 = canvas.toDataURL("image/jpeg", quality);
                // }
                callback(base64); //必须通过回调函数返回，否则无法及时拿到该值
            };
        },
        //上传图片
        uploadImgs (imgs, callback) {
            var that = this;
            var n_imgs = [];
            for (var i in imgs) {
                that.dealImage(imgs[i], 300, function (n_img) {
                    n_imgs.push(n_img);
                    if (i == imgs.length - 1) {
                        that
                            .$http({
                                url: that.api.uploadImg,
                                data: {
                                    imgs: JSON.stringify(n_imgs),
                                },
                            })
                            .then((res) => {
                                console.log(res);
                                if (res.code != 0) {
                                    that.$message.error(res.msg);
                                    callback(false);
                                } else {
                                    callback(res.data);
                                }
                            });
                    }
                });
            }
        },
        // 获取未读的通知数
        getNoticeNum () {
            this.$http({
                url: this.api.noticeNum,
            }).then((res) => {
                this.NoticeNum = res.data.sum;
            });
        },
        // currentPage 改变时会触发
        currentchange (currentPage) {
            console.log("currentPage 改变时会触发.", "当前页:" + currentPage);
            this.requestPage = currentPage;
            this.getNotice();
            this.isShowNotice = true;
        },
        // 用户点击上一页按钮改变当前页后触发
        prevClick () {
            console.log("用户点击上一页按钮改变当前页后触发");
            this.requestPage -= 1;
            this.getNotice();
            // 数据改变页面更新 再次显示
            this.isShowNotice = true;
        },
        // 用户点击下一页按钮改变当前页后触发
        nextclick () {
            console.log("用户点击下一页按钮改变当前页后触发");
            this.requestPage += 1;
            this.getNotice();
            // 数据改变页面更新 再次显示
            this.isShowNotice = true;
        },
        // ---------------------------------------
        // currentPage 改变时会触发(私信列表)
        privatechange (currentPage) {
            console.log("currentPage 改变时会触发.", "当前页:" + currentPage);
            this.privatePage = currentPage;
            this.getInfoList();
            this.isShowNotice = true;
        },
        // 用户点击上一页按钮改变当前页后触发(私信列表)
        privateprev () {
            console.log("用户点击上一页按钮改变当前页后触发");
            this.privatePage -= 1;
            this.getInfoList();
            // 数据改变页面更新 再次显示
            this.isShowNotice = true;
        },
        // 用户点击下一页按钮改变当前页后触发(私信列表)
        privatenext () {
            console.log("用户点击下一页按钮改变当前页后触发");
            this.privatePage += 1;
            this.getInfoList();
            // 数据改变页面更新 再次显示
            this.isShowNotice = true;
        },
        // ---------------------------------------
        // 返回聊天列表
        back () {
            this.pId = null;
            this.imgs = [];
        },
        // 关闭聊天窗口
        close () {
            this.isShowNotice = false;
            this.pId = null;
            this.activeIndex = 0;
            this.imgs = [];
        },
        // 进入聊天窗口
        enterChat (id) {
            console.log(id);
            this.pId = id;
            this.chat_list = [];
            this.getChatRecord();
        },
        // 通知私信切换
        active (index) {
            console.log(index);
            this.activeIndex = index;
            if (index === 1) {
                this.getInfoList();
            }
        },
        // 获取私信列表
        getInfoList () {
            this.$http({
                url: this.api.messageRecord,
                data: {
                    page: this.privatePage,
                    limit: 10,
                },
            }).then((res) => {
                console.log("私信列表", res);
                this.infoList = res.data.list;
                this.privateCount = res.data.count; //总条数
                this.privateCur = res.data.cur_page; //当前页数
                this.privateSum = res.data.page_sum; //总页数
                this.intoT = res.data.list.length == 0 ? '进入与客服聊天' : ''
            });
        },
        // 点击通知按钮 获取通知列表
        getNotice () {
            this.isShowNotice = !this.isShowNotice;
            this.$http({
                url: this.api.notice,
                data: {
                    page: this.requestPage,
                    limit: 10,
                },
            }).then((res) => {
                console.log(res);
                this.noticeList = res.data;
                this.countPage = res.data.count; //总条数
                this.curPage = res.data.cur_page; //当前页数
                this.pagSum = res.data.page_sum; //总页数
            });
        },
        goHome() {
            this.$router.push("index")
        },
        goRegister() {
            this.$router.push("register")
        },
        goLogin() {
            this.$router.push("login")
        },
        work () {
            this.$router.push("/home");
        },
        // 点击logo
        logo () {
            this.$router.push(this.path);
        },
    },
};
</script>
<style lang="less">
#header {
    background: #fff;
    width: 100%;
    min-width: 1200px;
    box-shadow: 0px 3px 7px 0px rgba(223, 223, 223, 0.35);
    z-index: 9;
    .iconfont {
        font-size: 24px;
        cursor: pointer;
    }
    .wrap {
        width: 1200px;
        margin: auto;
        height: 50px;
        position: relative;

        .left-a {
            height: 50px;
            float: left;
            display: flex;
            align-items: center;

            .logo {
                width: 120px;
                height: 30px;
                background: url(../assets/images/logoV1.1.png) no-repeat center;
                background-size: 120px;
                cursor: pointer;
            }

            .text {
                margin-left: 25px;
                font-size: 18px;
                font-family: FangSong Regular, FangSong Regular-Regular;
                font-weight: 400;
                text-align: left;
                color: #5d5d5d;
                display: inline-block;
            }
        }

        .right-a {
            height: 50px;
            float: right;
            display: flex;
            align-items: center;
            .link-home {
                margin-right: 30px;
                a {
                    color: #333333;
                    cursor: pointer;
                }
            }
            .work {
                cursor: pointer;

                .iconshouye {
                    color: #70ccff;
                    font-size: 20px;
                }

                color: #333333;
                font-size: 16px;
            }
            .login {
                width: auto;
                height: 29px;
                color: #333333;
                line-height: 29px;
                margin-left: 40px;
                font-size: 16px;
                cursor: pointer;
                padding: 0 15px;
                .register {
                    border-right: 1px solid #9f9f9f;
                    padding: 0 5px 0 0;
                }
                .Sign-in {
                    padding-left: 5px;
                }
            }
            .username {
                width: auto;
                height: 29px;
                background: #70ccff;
                color: white;
                line-height: 29px;
                margin-left: 40px;
                font-size: 12px;
                cursor: pointer;
                padding: 0 15px;
            }

            .username {
                cursor: text;
            }

            // 二维码按钮
            .app-link {
                /*width: 20px;*/
                /*height: 20px;*/
                /*margin-left: 30px;*/
                /*background: #000;*/
                cursor: pointer;
                font-size: 18px;

            }

            // 通知
            .notice {
                margin-left: 30px;
                position: relative;
                // 分页
                .pagin {
                    text-align: center;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }


                .inform {
                    position: absolute;
                    left: -182px;
                    top: 40px;
                    z-index: 999;
                    width: 388px;
                    height: 750px;
                    border-radius: 4px;
                    background: #fff;
                    box-shadow: 0 1px 4px #aaa;

                    .triangle-up {
                        width: 0;
                        height: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 12px solid #ededf0;
                        margin: auto;
                        position: relative;
                        bottom: 12px;
                    }

                    // 通知内容头部
                    .info-head {
                        width: 100%;
                        height: 59px;
                        border-bottom: 1px solid #eee;
                        position: relative;
                        bottom: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        a {
                            width: 86px;
                            height: 30px;
                            line-height: 30px;
                            border-radius: 4px 4px 0px 0px;
                        }

                        .aActive {
                            background: #70ccff;
                            color: #fff;
                        }
                    }

                    // 通知list
                    .noticeCent {
                        .noticeList {
                            height: 620px;
                            overflow-y: scroll;

                            li {
                                min-height: 60px;
                                max-height: 300px;
                                border-bottom: 1px solid #eee;
                                padding: 8px;
                                box-sizing: border-box;
                                text-align: left;

                                .title {
                                    font-size: 14px;
                                    color: #333;
                                    min-width: 70px;
                                    padding-bottom: 10px;
                                }

                                .itemTxt {
                                    text-indent: 2em;
                                    font-size: 14px;
                                    color: #70ccff;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    /*word-wrap: auto;*/
                                    word-break: break-all;
                                }
                            }
                        }

                        .noticeList::-webkit-scrollbar {
                            display: none !important;
                        }
                    }

                    // 私信
                    .privateCent {
                        .privateItem {
                            height: 620px;
                            overflow-y: scroll;

                            li {
                                min-height: 60px;
                                max-height: 300px;
                                padding: 8px 16px;
                                box-sizing: border-box;

                                .userId {
                                    font-size: 18px;
                                    color: #70ccff;
                                    display: block;
                                    font-weight: 700;
                                }

                                .itemTxt {
                                    display: inline-block;
                                    width: 60%;
                                    font-size: 14px;
                                    color: #666666;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-wrap: auto;
                                    word-break: break-all;
                                }

                                .time {
                                    width: 40%;
                                    float: right;
                                    font-size: 16px;
                                    color: #9f9f9f;
                                }
                            }

                            li:hover {
                                background: #fafafa;
                                cursor: pointer;
                            }
                        }

                        .privateItem::-webkit-scrollbar {
                            display: none !important;
                        }
                    }

                    // 聊天样式
                    .chat {
                        z-index: 8;
                        position: relative;
                        bottom: 12px;

                        .chatTitle {
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            background: #fafafa;

                            .back {
                                flex: 1;

                                div {
                                    margin: auto;
                                    width: 14px;
                                    height: 14px;
                                    border-top: 2px solid #999999;
                                    border-left: 2px solid #999999;
                                    transform: rotate(-45deg);
                                    cursor: pointer;
                                }
                            }

                            .centent {
                                flex: 3;
                                color: #333333;
                                letter-spacing: -1px;
                                font-weight: 700;
                                font-size: 18px;
                            }

                            .close {
                                flex: 1;

                                div {
                                    margin: auto;
                                    width: 24px;
                                    font-size: 24px;
                                    color: #999999;
                                    cursor: pointer;
                                }
                            }
                        }

                        .chatBoard {
                            height: 560px;
                            overflow-y: scroll;

                            .chat-r .chat-cont-b {
                                display: flex;
                                justify-content: flex-end;
                            }

                            .triangle-topleft {
                                width: 0;
                                height: 0;
                                border-top: 16px solid #eee;
                                border-left: 8px solid transparent;
                                margin-top: 7px;
                            }

                            .triangle-topright {
                                width: 0;
                                height: 0;
                                border-top: 16px solid #70ccff;
                                border-right: 8px solid transparent;
                                margin-top: 7px;
                            }

                            .chat-txt {
                                background: #70ccff;
                                color: #fff;
                                border-radius: 4px;
                            }

                            .chat-cont-t {
                                color: #888;
                            }

                            .chat-l {
                                text-align: left;
                            }

                            .chat-r {
                                text-align: right;
                            }

                            .chat-cont-b {
                                margin-top: 5px;
                            }

                            .chat-cont-b p {
                                margin: 6px 6px 8px 7px;
                            }

                            .chat-l .chat-cont-b {
                                position: relative;
                                left: -7px;
                                display: flex;
                            }

                            .chat-r .chat-cont-b {
                                position: relative;
                                right: -7px;
                            }

                            .chat-l .chat-cont-b .chat-txt {
                                background: #eee;
                                color: #2c3e50;
                            }

                            .chat-box {
                                margin: 25px 0px;
                                /* border:1px solid #00F; */
                            }

                            .chat-time span {
                                background: #eee;
                                color: #999;
                                padding: 2px 6px;
                                font-size: 14px;
                            }
                        }

                        .chatBoard::-webkit-scrollbar {
                            display: none !important;
                        }

                        .sendinfo {
                            height: 130px;
                            background: #fafafa;
                            box-sizing: border-box;
                            overflow: hidden;

                            .txtbox {
                                margin-top: 15px;
                                display: flex;
                                justify-content: center;
                                position: relative;

                                textarea {
                                    width: 352px;
                                    height: 60px;
                                    resize: none;
                                    border: none;
                                    padding: 12px;
                                    box-sizing: border-box;
                                    vertical-align: baseline;
                                    caret-color: #cccccc;
                                    font-size: 14px;
                                    outline: none;
                                }

                                textarea::-webkit-input-placeholder {
                                    font-size: 14px;
                                    font-family: Source Han Sans SC Regular,
                                        Source Han Sans SC Regular-Regular;
                                    font-weight: 400;
                                    text-align: left;
                                    color: #cccccc;
                                }

                                .maxlengthTips {
                                    position: absolute;
                                    bottom: 0;
                                    right: 24px;
                                    color: #cccccc;
                                    font-size: 12px;
                                }
                            }
                        }

                        .sendBox {
                            display: flex;
                            justify-content: space-between;
                            padding: 0 18px;
                            box-sizing: border-box;
                            margin-top: 10px;

                            .img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;

                                .icontupian {
                                    font-size: 32px;
                                    color: #999;
                                }
                            }

                            .imgSum {
                                position: absolute;
                                left: 40px;
                                right: 0;
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background: rgb(112, 204, 255);
                                font-size: 12px;
                                line-height: 16px;
                                text-align: center;
                                color: #fff;
                                cursor: pointer;
                            }

                            #img-file {
                                position: absolute;
                                width: 32px;
                                height: 60px;
                                display: none;
                            }

                            .sendBtn {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                a {
                                    display: inline-block;
                                    width: 68px;
                                    height: 35px;
                                    background: #70ccff;
                                    border-radius: 4px;
                                    font-size: 18px;
                                    line-height: 35px;
                                    text-align: center;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }

            .noticeActive {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                color: #fff;
                background: red;
                position: absolute;
                top: 0;
                right: 0;
                font-size: 5px;
                text-align: center;
                line-height: 12px;
                cursor: pointer;
            }
        }
    }
}
</style>
